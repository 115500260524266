
import ventEvo from "./VEN-Evo.webp"
import cadT70 from './CAD-T70.webp'
import traExample from './TRA-Example.webp'
import nebInno from './NEB-Inno.webp'
import oxySupplies from './OXY-Supplies.webp'
import oxiPM100 from './OXI-PM100.webp'
import apnSM2 from './APN-SM2.webp'
import cpaAS10 from './CPA-AS10.webp'
import sucVa from './SUC-Vac.webp'

const equipmentItems = [
  {
    img: ventEvo,
    title: "Ventilators",
    description: "Your partner in pediatric respiratory care. Seamlessly blending innovation and comfort, the ventilator empowers families with advanced technology and user-friendly features. Lightweight and portable, it's more than a device – it's confidence, comfort, and quality of life.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: cadT70,
    title: "Cough Assist Devices",
    description: "A vital tool in pediatric respiratory care. Engineered to support your child's respiratory health, these devices offer a gentle and effective way to assist with coughing and airway clearance. Designed with innovation and precision, Cough Assist Devices provide a non-invasive solution that promotes better lung function and enhances overall well-being.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: traExample,
    title: "Tracheostomies",
    description: "These specialized trachs offer a crucial pathway to optimal lung function and enhanced well-being. Designed with precision and compassion, Tracheostomy Care ensures that your child's airway remains clear and open, promoting comfortable breathing and reducing the risk of complications.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: nebInno,
    title: "Nebulizers",
    description: "Experience the transformative power of Nebulizers – a cornerstone of pediatric respiratory care. These compact devices deliver medication in a fine mist, ensuring effective treatment for various respiratory conditions. Nebulizers provide a gentle and child-friendly way to administer vital medications, making each treatment session more comfortable and manageable for both parents and little ones.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: oxySupplies,
    title: "Oxygen Therapy",
    description: "Embark on a journey of Oxygen Therapy – a lifeline of breathable support for your child's well-being. Oxygen Therapy offers a nurturing boost to respiratory health, ensuring that your child receives the essential oxygen they need to thrive. This gentle and effective therapy provides a breath of fresh air, enabling your child's body to function optimally and aiding in a healthier, more vibrant life.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: oxiPM100,
    title: "Oximeters",
    description: "Discover the assurance of Oximeters – guardians of your child's oxygen levels. These portable devices offer real-time monitoring, allowing you to stay connected to your child's health journey. With a simple and non-invasive procedure, Oximeters help ensure your child receives the optimal oxygen support they need to thrive and grow.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: apnSM2,
    title: "Apnea Monitors",
    description: "Embrace peace of mind with Apnea Monitors – vigilant protectors of your child's breathing patterns. These monitors track respiratory activity, providing an early alert in the event of irregularities. Apnea Monitors offer parents a sense of security, enabling them to respond swiftly and confidently to any changes, and ensuring that your child's breathing remains steady and consistent.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: cpaAS10,
    title: "C-PAP / Bi-PAP",
    description: "Elevate your child's respiratory support with C-PAP/Bi-PAP devices – personalized airflow companions for better sleep and lung function. These machines provide continuous positive airway pressure, assisting in easier breathing during sleep. Designed for comfort and adaptability, C-PAP/Bi-PAP devices contribute to enhanced rest and overall well-being.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  },
  {
    img: sucVa,
    title: "Suction Machines",
    description: "Experience the relief brought by Suction Machines – allies in maintaining clear airways. These devices aid in removing excess mucus and secretions, promoting optimal respiratory hygiene. With user-friendly features, Suction Machines enable caregivers to provide essential airway clearance, ensuring your child breathes easily and comfortably.",
    mediaSizes: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3
    }
  }
]

export default equipmentItems;