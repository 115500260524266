import { Box, Button, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function InsuranceInformation() {
  const theme = useTheme()
  const { t } = useTranslation('InsuranceInformation');
  // Hooks and Variables
  const isMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  // List of accepted insurances for easy management and updates
  const nonCommercialInsurances = ["Medicaid", "Medicare"];
  const commercialInsurances = ["Blue Cross Blue Shield", "Cigna", "Humana", "Tricare", "Viva"];

  const structuredData = {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "name": t('insuranceInformation_InsuranceInfoTitle'),
        "description": t('insuranceInformation_IfYouDoNotSeeInsurance'),
        "url": window.location.href
      },
      ...nonCommercialInsurances.map(insurance => ({
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": insurance
        },
        "provider": {
          "@type": "Organization",
          "name": "Medical Resources Inc"
        },
        "category": "Non-Commercial Insurance"
      })),
      ...commercialInsurances.map(insurance => ({
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": insurance
        },
        "provider": {
          "@type": "Organization",
          "name": "Medical Resources Inc"
        },
        "category": "Commercial Insurance"
      }))
    ]
  };

  return (
    <Box sx={{ p: 4 }}>
      <Helmet>
        <title>MDR - Accepted Insurances</title>
        <meta name="description" content="Find out which insurance providers are accepted by Medical Resources Inc." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 50% 50%, hsl(180, 60%, 90%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 10% 90%, hsl(0, 50%, 90%) 0%, rgba(255,255,255,0) 70%)`
      }} />
      {/* Insurance Providers Information */}
      <Typography variant="h1" textAlign="center" pb={4} gutterBottom>{t('insuranceInformation_InsuranceInfoTitle')}</Typography>
      <Paper sx={{ p: 4, mb: 3 }}>
        <Grid2 container spacing={4} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
          <Grid2 xs={12} textAlign="center" sx={{ mb: 3 }}>
            <Typography variant="h2">{t("insuranceInformation_AcceptedInsuranceProviders")}</Typography>
            <Typography sx={{ mt: 2 }}>{t("insuranceInformation_IfYouDoNotSeeInsurance")}</Typography>
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} textAlign={isMd ? "center" : "right"}>
            <Typography variant="h6" sx={{ mb: 2 }}>{t("insuranceInformation_NonCommercial")}</Typography>
            {nonCommercialInsurances.map(insurance => <Typography key={insurance} sx={{ mb: 1 }}>{insurance}</Typography>)}
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} textAlign={isMd ? "center" : "left"}>
            <Typography variant="h6" sx={{ mb: 2 }}>{t("insuranceInformation_Commercial")}</Typography>
            {commercialInsurances.map(insurance => <Typography key={insurance} sx={{ mb: 1 }}>{insurance}</Typography>)}
          </Grid2>
          <Grid2 xs={12} textAlign="center">
            <Typography variant="caption" sx={{ mt: 2 }}>{t("insuranceInformation_OtherInsuranceNote")}</Typography>
          </Grid2>
        </Grid2>
      </Paper>

      {/* Change DME Provider Information */}
      <Typography variant="h1" textAlign="center" pb={4}>{t("insuranceInformation_ChangeDMEProviderTitle")}</Typography>
      <Paper sx={{ p: 4, textAlign: 'center', mb: 3 }}>
        <Typography sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1, mb: 3 }}>{t("insuranceInformation_ToChangeDMEProvider")}</Typography>
        <Button variant="contained" component={Link} to="/ChangeOfProvider">{t("insuranceInformation_ChangeDMEProviderButton")}</Button>
      </Paper>
    </Box>
  );
}

export default InsuranceInformation;
