import BrockJ from "./BrockJCropped.webp"
import CharlotteH from "./CharlotteHCropped.webp"
import DeeS from "./DeeSCropped.jpg"
import DanielR from "./DanielRCropped.webp"
import DianaB from "./DianaBCropped.webp"
import EllisW from "./EllisWCropped.webp"
import EricaF from "./EricaFCropped.webp"
import GregA from "./GregACropped.webp"
import KathyJ from "./KathyJ.webp"
import KaylaR from "./KaylaRCropped.webp"
import KierraD from "./KierraDCropped.webp"
import LonnieH from "./LonnieHCropped.webp"
import ManuelB from "./ManuelBCropped.webp"
import PamT from "./PamTCropped.webp"
import RossW from "./RossW.webp"
import StevenMv2 from "./StevenMv2.webp"
import ZacP from "./ZacPCropped.webp"

const staffObject = {
  Founders: [
    {
      name: "Bruce Jones"
    },
    {
      profileImage: KathyJ,
      name: "Kathy Jones"
    },
  ],
  "General Manager": [
    {
      profileImage: RossW,
      name: "Ross Wilson"
    }
  ],
  "Prior Authorizations / Billing": [
    {
      name: "Daniel Richey",
      position: 'PA Manager',
      profileImage: DanielR
    },
    {
      name: "Zac Patterson",
      position: 'EPSDT Specialist',
      profileImage: ZacP
    },
    {
      name: "Pam Tidwell",
      profileImage: PamT
    },
    {
      name: "Charlotte Hagan",
      quote: "Every breath counts!",
      profileImage: CharlotteH
    },
  ],
  "Service / RRT / Biomed": [
    {
      name: "Lonnie Hyde",
      position: 'Service Manager',
      profileImage: LonnieH
    },
    {
      name: "Brock Jones",
      position: 'Service Rep',
      profileImage: BrockJ
    },
    {
      name: "Dee Spencer",
      position: 'Biomed',
      profileImage: DeeS,
      quote: "\"Sometimes it takes only one act of kindness and caring to change a person’s life.\""
    },
    {
      name: "Erica Frazier",
      position: 'RRT',
      profileImage: EricaF
    },
    {
      name: "Greg Allred",
      position: 'RRT',
      profileImage: GregA
    },
    {
      name: "Kierra Daniels",
      position: 'RRT',
      profileImage: KierraD
    },
  ],
  "Shipping / Purchasing": [
    {
      name: "Charleston Lewis",
      position: 'Shipping Manager'
    },
    {
      name: "Ellis White",
      position: 'Shipping Rep',
      profileImage: EllisW
    },
    {
      name: "Ronda Watson",
      position: "Purchasing Manager"
    }
  ],
  "Intake / Patient Representative": [
    {
      profileImage: DianaB,
      name: "Diana Bell",
      position: "Intake Rep"
    },
    {
      profileImage: KaylaR,
      name: 'Kayla Rich',
      position: "Patient Rep / RRT",
      quote: "\"I love my patients!!\""
    }
  ],
  "Information Technology / Projects": [
    {
      profileImage: StevenMv2,
      name: "Steven Marshall",
      position: "IT Administrator & Software Dev",
      hiddenMessage: "I made this site! 😄",
      quote: "\"The past can hurt. But the way I see it, you can either run from it or learn from it.\" - Rafiki (Lion King)"
    },
    {
      name: "Manuel Bueso",
      position: 'Project Specialist',
      profileImage: ManuelB
    }
  ]
}

export { staffObject };