import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, List, ListItem, ListItemText, Modal, Snackbar, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactSignatureCanvas from "react-signature-canvas";

function ChangeOfProvider() {
  const theme = useTheme();
  let isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const testing = true;
  const [inputFields, setInputFields] = useState({
    demographicsFirstName: testing ? "Steven" : "",
    demographicsLastName: testing ? "Marshall" : "",
    demographicsDateOfBirth: testing ? "1993-09-29" : "",
    addressStreet: testing ? "1516 5th Ave S" : "",
    addressStreetTwo: testing ? "Suite 1516" : "",
    addressCity: testing ? "Birmingham" : "",
    addressState: "Alabama",
    addressZip: testing ? "35233" : "",
    caregiverName: testing ? "Steven" : "",
    caregiverPhone: testing ? "2055217337" : "",
    caregiverEmail: testing ? "wecare@mdr.net" : "",
    primaryDoctorName: testing ? "Steven" : "",
    primaryDoctorPhone: testing ? "2055217337" : "",
    secondaryDoctorName: testing ? "Steven" : "",
    secondaryDoctorPhone: testing ? "2055217337" : "",
    insurancePrimaryName: testing ? "Medicaid of AL" : "",
    insurancePrimaryPolicy: testing ? "#1234567890" : "",
    insuranceSecondaryName: testing ? "BCBS of AL" : "",
    insuranceSecondaryPolicy: testing ? "#1234567890" : "",
    lastProviderName: testing ? "Medical Resources Inc" : "",
    lastProviderPhone: testing ? "2055217337" : "",
    signerFirstName: testing ? "Steven" : "",
    signerLastName: testing ? "Marshall" : "",
    signerDate: new Date().toISOString().split("T")[0]
  })
  const [equipments, setEquipments] = useState({
    "Ventilator": testing ? true : false,
    "Tracheostomy": testing ? true : false,
    "Suction Machine": testing ? true : false,
    "Bivona": testing ? true : false,
    "Oxygen Therapy": testing ? true : false,
    "Oximeter Monitor": testing ? true : false,
    "Cough Assist Device": testing ? true : false,
    "BiPAP": testing ? true : false,
    "CPAP": testing ? true : false,
    "Apnea Monitor": testing ? true : false,
    "Nebulizer": testing ? true : false
  })
  const [signature, setSignature] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [DocURI, setDocURI] = useState("")
  const [error, setError] = useState({
    message: "",
    invalidFields: []
  })

  const { t } = useTranslation("changeOfProvider");

  const sigCanvas = useRef({});

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null)
  };

  const saveSignature = () => {
    setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let packagedData = {
      ...inputFields,
      ...equipments,
      signature
    }
    try {
      const response = await fetch('https://mainsiteapi.mdr.net/changeOfProvider', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // Add any additional headers here
        },
        body: JSON.stringify(packagedData) // Convert the JavaScript object to a JSON string
      });

      // Check if the request was successful
      if (!response.ok) {
        const data = await response.json(); // Assuming server responds with json
        setError({
          message: data.message,
          invalidFields: data.invalidFields || [], // Ensure it falls back to an empty array if undefined
          isFieldError: data.isFieldError || false
        });
        setModalOpen(!data.isFieldError)
        throw new Error('Network response was not ok' + response.statusText);
      } else {
        // Process the response if needed
        const data = await response.text(); // Assuming server responds with json
        setDocURI(data)
        setModalOpen(true)
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };

  function updateForm(e) {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setEquipments(prevEquipments => ({
        ...prevEquipments,
        [name]: checked
      }));
    } else {
      setInputFields(prevInputFields => ({
        ...prevInputFields,
        [name]: value
      }));
    }
  }

  useEffect(() => {
    if (error.invalidFields.length > 0) {
      // Focus on the first invalid field when the modal is closed
      const firstInvalid = document.getElementsByName(error.invalidFields[0][0])[0];

      if (firstInvalid === 'equipment' || firstInvalid === 'signature') {
        // firstInvalid.scrollIntoView({ behavior: 'smooth' })
        window.scrollBy({ top: firstInvalid.getBoundingClientRect().top - 119.96, behavior: 'smooth' })
      } else {
        // firstInvalid.focus()
        window.scrollBy({ top: firstInvalid.getBoundingClientRect().top - 119.96, behavior: 'smooth' })
      }
    }
  }, [error]);

  return (
    <Grid2 container>
      <Helmet>
        <title>MDR - Change of Provider</title>
        <meta name="description" content="Change your medical equipment provider easily with our form. Provide us with your details and we'll take care of the rest." />
        <meta name="keywords" content="medical equipment, provider change, health care, medical resources" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Change of Provider Form",
              "description": "You deserve to choose your own provider!",
              "publisher": {
                "@type": "Organization",
                "name": "MDR"
              }
            }
          `}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 20% 60%, hsl(85, 60%, 82%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 80% 40%, hsl(265, 60%, 92%) 0%, rgba(255,255,255,0) 70%)`
      }} />
      <Grid2 xs={12} sx={{ mb: 4, backgroundColor: theme.palette.background.default, borderRadius: 4, mt: 2, p: 1 }}>
        <Typography variant="h1" textAlign="center" gutterBottom>{t('changeOfProvider_title')}</Typography>
        <Typography textAlign="center" sx={{ mb: 2 }}>
          {t("changeOfProvider_subtitle1")}
        </Typography>
        <Typography textAlign="center" variant="h6" sx={{ mb: 2 }}>
          {t("changeOfProvider_subtitle2")}
        </Typography>
        <Typography textAlign="center" variant="subtitle1" sx={{ mb: 1 }}>
          {t("changeOfProvider_subtitle3")}
        </Typography>
      </Grid2>
      <Grid2 component="form" onChange={updateForm} onSubmit={handleSubmit} container xs={12} spacing={2} p={1} justifyContent="center" sx={{ mb: 4, backgroundColor: theme.palette.background.default, borderRadius: 4, mt: 1, p: 1 }}>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_patientDemographics")}</Typography>
            <TextField label={t("changeOfProvider_firstName")} value={inputFields.demographicsFirstName} name="demographicsFirstName" required />
            <TextField label={t("changeOfProvider_lastName")} value={inputFields.demographicsLastName} name="demographicsLastName" required />
            <TextField label={t("changeOfProvider_dateOfBirth")} type="date" InputLabelProps={{ shrink: true }} value={inputFields.demographicsDateOfBirth} name="demographicsDateOfBirth" required />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_patientAddress")}</Typography>
            <TextField label={t("changeOfProvider_street")} value={inputFields.addressStreet} name="addressStreet" required />
            <TextField label={t("changeOfProvider_streetTwo")} value={inputFields.addressStreetTwo} name="addressStreetTwo" />
            <TextField label={t('changeOfProvider_city')} value={inputFields.addressCity} name="addressCity" required />
            <Tooltip title="We typically only service the state of Alabama. Please contact us if you are not located within the state of Alabama." enterTouchDelay={0} leaveTouchDelay={5000}>
              <TextField label={t("changeOfProvider_state")} value={inputFields.addressState} name="addressState" disabled required />
            </Tooltip>
            <TextField label={t("changeOfProvider_zip")} value={inputFields.addressZip} name="addressZip" required />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_caregiverInformation")}</Typography>
            <TextField label={t("changeOfProvider_name")} value={inputFields.caregiverName} name="caregiverName" required />
            <TextField label={t("changeOfProvider_phone")} value={inputFields.caregiverPhone} name="caregiverPhone" required />
            <TextField label={t("changeOfProvider_email")} value={inputFields.caregiverEmail} name="caregiverEmail" required />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_primaryDoctor")}</Typography>
            <TextField label={t("changeOfProvider_name")} value={inputFields.primaryDoctorName} name="primaryDoctorName" />
            <TextField label={t("changeOfProvider_phone")} value={inputFields.primaryDoctorPhone} name="primaryDoctorPhone" />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_orderingDoctor")}</Typography>
            <TextField label={t("changeOfProvider_name")} value={inputFields.secondaryDoctorName} name="secondaryDoctorName" />
            <TextField label={t("changeOfProvider_phone")} value={inputFields.secondaryDoctorPhone} name="secondaryDoctorPhone" />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_insuranceInformation")}</Typography>
            <TextField label={t("changeOfProvider_primaryInsuranceName")} value={inputFields.insurancePrimaryName} name="insurancePrimaryName" required />
            <TextField label={t("changeOfProvider_primaryInsurancePolicy")} value={inputFields.insurancePrimaryPolicy} name="insurancePrimaryPolicy" required />
            <TextField label={t("changeOfProvider_secondaryInsuranceName")} value={inputFields.insuranceSecondaryName} name="insuranceSecondaryName" />
            <TextField label={t("changeOfProvider_secondaryInsurancePolicy")} value={inputFields.insuranceSecondaryPolicy} name="insuranceSecondaryPolicy" />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack spacing={2}>
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_lastProviderInformation")}</Typography>
            <TextField label={t("changeOfProvider_name")} value={inputFields.lastProviderName} name="lastProviderName" required />
            <TextField label={t("changeOfProvider_phone")} value={inputFields.lastProviderPhone} name="lastProviderPhone" />
          </Stack>
        </Grid2>
        <Grid2>
          <Stack justifyContent="center">
            <Typography textAlign="center" variant="h6" name="equipment">{t("changeOfProvider_equipment")}</Typography>
            <FormControl required>
              <FormGroup>
                <FormLabel>Must Pick One</FormLabel>
                <FormControlLabel control={<Checkbox checked={equipments["Ventilator"]} name="Ventilator" />} label={t("changeOfProvider_ventilator")} />
                <FormControlLabel control={<Checkbox checked={equipments["Tracheostomy"]} name="Tracheostomy" />} label={t("changeOfProvider_tracheostomy")} />
                <FormControlLabel control={<Checkbox checked={equipments["Suction Machine"]} name="Suction Machine" />} label={t("changeOfProvider_suctionMachine")} />
                <FormControlLabel control={<Checkbox checked={equipments["Bivona"]} name="Bivona" />} label={t("changeOfProvider_bivona")} />
                <FormControlLabel control={<Checkbox checked={equipments["Oxygen Therapy"]} name="Oxygen Therapy" />} label={t("changeOfProvider_oxygenTherapy")} />
                <FormControlLabel control={<Checkbox checked={equipments["Oximeter Monitor"]} name="Oximeter Monitor" />} label={t("changeOfProvider_oximeterMonitor")} />
                <FormControlLabel control={<Checkbox checked={equipments["Cough Assist Device"]} name="Cough Assist Device" />} label={t("changeOfProvider_coughAssistDevice")} />
                <FormControlLabel control={<Checkbox checked={equipments["BiPAP"]} name="BiPAP" />} label={t("changeOfProvider_biPAP")} />
                <FormControlLabel control={<Checkbox checked={equipments["CPAP"]} name="CPAP" />} label={t("changeOfProvider_cPAP")} />
                <FormControlLabel control={<Checkbox checked={equipments["Apnea Monitor"]} name="Apnea Monitor" />} label={t("changeOfProvider_apneaMonitor")} />
                <FormControlLabel control={<Checkbox checked={equipments["Nebulizer"]} name="Nebulizer" />} label={t("changeOfProvider_nebulizer")} />
              </FormGroup>
            </FormControl>
          </Stack>
        </Grid2>
        <Grid2 xs={12} sx={{ mb: 3 }}>
          <Typography textAlign="center" variant="h6" gutterBottom>{t("changeOfProvider_patientChangeOfProvider")}</Typography>
          <Typography sx={{ mb: 2 }}>
            {t("changeOfProvider_descriptor1")}
          </Typography>
          <List sx={{ ml: 2, mb: 2 }}>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor2")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor3")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor4")}</ListItemText>
            </ListItem>
          </List>
          <Typography sx={{ mb: 2 }}>{t("changeOfProvider_descriptor5")}</Typography>
          <List sx={{ ml: 2, mb: 2 }}>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor6")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor7")}</ListItemText>
            </ListItem>
          </List>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Medical Resources:</Typography>
          <Typography sx={{ mb: 2 }}>{t("changeOfProvider_descriptor8")}</Typography>
        </Grid2>
        <Grid2 xs={12} sx={{ mb: 3 }}>
          <Typography textAlign="center" variant="h6" gutterBottom>{t("changeOfProvider_authorization")}</Typography>
          <Typography sx={{ mb: 2 }}>
            {t("changeOfProvider_descriptor9")}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {t("changeOfProvider_descriptor10")}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {t("changeOfProvider_descriptor11")}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {t("changeOfProvider_descriptor12")}
          </Typography>
          <List sx={{ ml: 2, mb: 2 }}>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor13")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>{t("changeOfProvider_descriptor14")}</ListItemText>
            </ListItem>
          </List>
        </Grid2>
        <Grid2 xs={12} sx={{ mb: 3 }}>
          <Typography textAlign="center" variant="h6" gutterBottom>{t("changeOfProvider_signatureAndDate")}</Typography>
          <Stack direction="row" spacing={2} justifyContent="center" mb={1}>
            <TextField label={t("changeOfProvider_firstName")} name="signerFirstName" value={inputFields.signerFirstName} required />
            <TextField label={t("changeOfProvider_lastName")} name="signerLastName" value={inputFields.signerLastName} required />
            <Tooltip title="Date is automatically set to today and cannot be changed.">
              <TextField label={t("changeOfProvider_todaysDate")} type="date" name="signerDate" InputLabelProps={{ shrink: true }} value={inputFields.signerDate} disabled />
            </Tooltip>
          </Stack>
          <Stack direction="column" spacing={2} justifyContent="center">
            <Typography textAlign="center" variant="h6">{t("changeOfProvider_fullNameSignature")}</Typography>
            <ReactSignatureCanvas ref={sigCanvas} canvasProps={{ name: "signature", style: { backgroundColor: "white", border: 'solid 2px black' } }} onEnd={saveSignature} />
            <Button onClick={clearSignature}>{t("changeOfProvider_clearSignature")}</Button>
          </Stack>
        </Grid2>
        <Grid2 xs={12} sx={{ mt: 3 }} textAlign="center">
          <Button variant="contained" type="submit">{t("changeOfProvider_submit")}</Button>
        </Grid2>
      </Grid2>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        {DocURI ? <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: !isSm ? '95vh' : null,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 1,
          }}
        >
          <Typography textAlign="center" variant="h1" m={0}>Form Submitted Successfully!</Typography>
          <Typography textAlign="center" variant="h4" m={0}>Expect to hear from one of our representatives soon!</Typography>
          {!isSm ? (
            <iframe
              src={DocURI}
              width="100%"
              title="Document Preview"
              style={!isSm ? { height: '95%' } : null}
            />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.href = DocURI}
              fullWidth
            >
              Download Document
            </Button>
          )}
        </Box> :
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
              textAlign: 'center'
            }}>
            <Typography textAlign="center" variant="h1">Loading</Typography>
            <CircularProgress />
          </Box>}
      </Modal>
      <Modal
        open={!!error.message && !error.isFieldError} // open when there is an error message
        onClose={() => setError({ message: "", invalidFields: [] })} // clear error on close
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>Error</Typography>
          <Typography variant="body1" mb={2}>{error.message}</Typography>
          <Typography variant="body2" color="error.main">Invalid Fields: {error.invalidFields.map(elm => elm[1]).join(', ')}</Typography>
          <Button variant="contained" color="primary" onClick={() => setError({ message: "", invalidFields: [] })}>Close</Button>
        </Box>
      </Modal>
      <Snackbar
        open={!!error.message && error.isFieldError}
        autoHideDuration={6000}
        onClose={() => setError({ message: "", invalidFields: [] })}
        message={error.message + error.invalidFields.map(elm => elm[1]).join(', ')}
      />
    </Grid2>
  );
}

export default ChangeOfProvider;