import React from 'react';
import { Button, ButtonGroup, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import usFlag from "../images/us.svg"
import mxFlag from "../images/mx.svg"

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <ButtonGroup variant="contained" color="primary" aria-label="text primary button group" sx={{ boxShadow: 'none' }}>
      <Tooltip title="English">
        <Button onClick={() => changeLanguage('en')}>
          <img src={usFlag} alt="USA Flag" width="20" /><Typography sx={{ ml: 1 }}>EN</Typography>
        </Button>
      </Tooltip>
      <Tooltip title="Español">
        <Button onClick={() => changeLanguage('es')}>
          <img src={mxFlag} alt="Mexico Flag" width="20" /><Typography sx={{ ml: 1 }}>ES</Typography>
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default LanguageSwitcher;
