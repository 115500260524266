import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardMedia, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import equipmentItems from "../images/ItemImages/equipmentItems";
import { ExpandMoreTwoTone } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

function Equipment() {
  const theme = useTheme()
  const { t } = useTranslation('Equipment');

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    "name": "Pediatric Respiratory Equipment",
    "description": "See all the equipment we have for children!",
    "itemListElement": equipmentItems.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "Product",
        "name": item.title,
        "image": item.img,
        "description": item.description
      }
    }))
  };

  return (
    <Box sx={{ p: 4 }}>
      <Helmet>
        <title>MDR - Equipment & Supplies</title>
        <meta name="description" content="See all the equipment we have for children!" />
        <meta name="keywords" content="equipment, equipment details, health care, medical resources" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 10% 10%, hsl(328, 54%, 88%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 90% 10%, hsl(296, 21%, 84%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 10% 90%, hsl(190, 100%, 95%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 90% 90%, hsl(160, 62%, 85%) 0%, rgba(255,255,255,0) 70%)`
      }} />

      <Typography variant="h1" textAlign="center" gutterBottom>
        {t('equipment_PediatricRespiratoryEquipmentTitle')}
      </Typography>

      <Typography variant="subtitle1" textAlign="center" gutterBottom>
        {t('equipment_EquipmentSubtitle')}
      </Typography>

      <Grid2 container spacing={4}>
        {equipmentItems.map(({ description, img, mediaSizes, title }) => (
          <Grid2
            key={title}
            xs={mediaSizes.xs}
            sm={mediaSizes.sm}
            md={mediaSizes.md}
            lg={mediaSizes.lg}
          >
            <Card elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CardMedia
                image={img}
                title={title}
                sx={{ height: 250, width: '100%', objectFit: 'cover' }}
              />

              <Accordion defaultExpanded={true} sx={{ width: '100%', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
                  <Typography variant="subtitle1">
                    {t(title)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
                    {t(description)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Grid2>
        ))}
      </Grid2>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h2" textAlign="center">
          {t('equipment_ComfortableLifeStatement')}
        </Typography>
      </Box>
    </Box>
  );
}

export default Equipment;