import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#8564C2"
    },
    secondary: {
      main: "#a1c264"
    },
    background: {
      default: "#fff",
      paper: "#cccceb"
    },
    divider: "#33339f"
  },
  typography: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '2.5rem',
      lineHeight: 1.4,
    },
    h2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '2rem',
      lineHeight: 1.4,
    },
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '1.75rem',
      lineHeight: 1.4,
      fontWeight: 800
    },
    h4: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '1.5rem',
      lineHeight: 1.4,
      fontWeight: 800
    },
    h5: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '1.25rem',
      lineHeight: 1.4,
      fontWeight: 800
    },
    h6: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '1rem',
      lineHeight: 1.4,
      fontWeight: 800
    },
    subtitle1: {
      fontSize: '1.25rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
    button: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '1rem',
    },
    body1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: '1.125rem',
      letterSpacing: '0.01em',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
            backgroundColor: theme.palette.primary.dark
          }
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 14,
          transition: 'box-shadow 0.3s ease',
        },
        contained: ({ theme }) => ({
          background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
          backgroundColor: theme.palette.primary.dark,
          '&:hover': {
            background: theme.palette.primary.light,
          },
          '&:active': {
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
            transform: 'translateY(2px)',
          }
        }),
        outlined: ({ theme }) => ({
          borderColor: 'transparent',
          boxShadow: `0 0 0 2px ${theme.palette.primary.dark}, 0 0 0 4px ${theme.palette.primary.light}`,
          '&:hover': {
            border: theme.palette.primary.light,
          },
          '&:active': {
            boxShadow: `0 4px 12px rgba(0, 0, 0, 0.2), 0 0 0 2px ${theme.palette.primary.light}`,
            transform: 'translateY(2px)',
          }
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff", // Solid white background
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.info.dark
        })
      }
    }
  }
})

export default theme;