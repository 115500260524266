import { useTranslation } from 'react-i18next';
import { Directions, Email, Fax, Phone } from '@mui/icons-material';
import theComplianceTeamCertificationBadge from '../images/TheComplianceTeam_EP_badge_865px.webp'
import { Avatar, Box, Card, CardMedia, Link, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Link as RouterLink } from 'react-router-dom';

// List of contact items for easy management and maintainability
const contactItems = [
  { href: 'https://maps.app.goo.gl/dvihj7ZKDTt3Gk6r9', icon: <Directions />, primary: "footer_Location", secondary: "footer_LocationAddress" },
  { href: 'tel:12055217337', icon: <Phone />, primary: "footer_Phone", secondary: "footer_PhoneNumber" },
  { href: 'tel:12055210585', icon: <Fax />, primary: "footer_Fax", secondary: "footer_FaxNumber" },
  { href: 'mailTo:wecare@mdr.net', icon: <Email />, primary: "footer_Email", secondary: "footer_EmailAddress" }
];

function ContactItem({ href, icon, primary, secondary }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ListItem>
      <Link component={RouterLink} to={href} target='_blank' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <ListItemAvatar>
          <Avatar>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={t(primary)} secondary={t(secondary)} secondaryTypographyProps={{ "color": theme.palette.primary.dark }} />
      </Link>
    </ListItem>
  );
}

function Footer() {
  const { t } = useTranslation();
  const theme = useTheme();

  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 1, borderRadius: '14px 14px 0 0' }}>
      <Grid2 container spacing={4}>

        {/* Contact Section */}
        <Grid2 xs={12} sm={6} md={4} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
          <List sx={{ background: theme.palette.background.default, borderRadius: 4 }}>
            {contactItems.map(item => <ContactItem key={item.primary} {...item} />)}
            {/* Embedding the Map */}
            <ListItem>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.8418712672533!2d-86.81276930576634!3d33.50549100469891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88891bec451ee8dd%3A0x70342b7c15cb2392!2sMedical%20Resources%2C%20Inc.!5e0!3m2!1sen!2sus!4v1697646938873!5m2!1sen!2sus" title='Map and Directions' style={{ border: 0, width: '100%', height: '200px' }} allowFullScreen loading="lazy"></iframe>
            </ListItem>
          </List>
        </Grid2>

        {/* Additional Links Section */}
        <Grid2 xs={12} sm={6} md={4} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
          <Box textAlign="center" display="flex" flexDirection="column" borderRadius={4} p={1} sx={{ background: theme.palette.background.default }}>
            <Typography variant='h4'>{t('footer_AdditionalLinks')}</Typography>
            <RouterLink to="/ChangeOfProvider" onClick={scrollToTop}><Typography variant='button'>{t('footer_ChangeOfProvider')}</Typography></RouterLink>
            <RouterLink to="/insuranceInformation" onClick={scrollToTop}><Typography variant='button'>{t('footer_InsuranceInformation')}</Typography></RouterLink>
            <RouterLink to="/contact" onClick={scrollToTop}><Typography variant='button'>{t('footer_ContactUs')}</Typography></RouterLink>
            <RouterLink to="/careers" onClick={scrollToTop}><Typography variant='button'>{t('footer_JoinOurTeam')}</Typography></RouterLink>
            <RouterLink to="https://medicalresources.hmebillpay.com/Home/Index" target='_blank'><Typography variant='button'>{t('footer_PayYourBillOnline')}</Typography></RouterLink>
            <RouterLink to="https://medicalresourcesincdot.wordpress.com/" target='_blank'><Typography variant='button'>{t('footer_NewsUpdates')}</Typography></RouterLink>
          </Box>
        </Grid2>

        {/* Certification Badge Section */}
        <Grid2 xs={12} sm={6} md={4} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
          <Card>
            <CardMedia component="img" image={theComplianceTeamCertificationBadge} sx={{ p: 1, backgroundColor: 'white' }} alt='Compliance Team Certification' />
          </Card>
        </Grid2>

        {/* Copyright Section */}
        <Grid2 xs={12} display='flex' justifyContent='center' padding={1}>
          <Typography variant='caption' textAlign="center">{t('footer_Copyright')}<Link href="/about#Steven%20Marshall">Steven Marshall</Link></Typography>
        </Grid2>
      </Grid2>
    </Paper>
  );
}

export default Footer;
