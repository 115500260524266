import { Outlet } from "react-router-dom";
import Navigation from "../components/nav";
import Footer from "../components/footer";
import { Container, CssBaseline, Typography } from '@mui/material'
import LanguageSwitcher from '../components/languageSwitcher';
import FeedbackModal from '../components/FeedBackModal';
import { Suspense } from "react";


function Root() {
  return (
    <Container maxWidth="xl" sx={{
      pb: 8
    }}>
      <CssBaseline />
      <Navigation />
      <Suspense fallback={<Typography variant="h1" textAlign="center">Loading...</Typography>}>
        <Outlet />
      </Suspense>
      <div style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
        backgroundColor: 'transparent',
        zIndex: 100
      }}>
        <LanguageSwitcher />
        <FeedbackModal />
      </div>
      <Footer />
    </Container>
  );
}

export default Root;