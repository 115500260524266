import { useTranslation } from 'react-i18next';
import logo from '../images/MDRLogo.webp';
import fbLogo from '../images/FacebookLogo.webp';
import messengerLogo from '../images/MessengerLogo.svg';
import instaLogo from '../images/InstagramLogo.webp';
import blogLogo from '../images/BlogLogo.webp';
import { Avatar, Box, Button, Hidden, IconButton, Link, List, ListItemButton, ListItemText, Modal, Paper, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Close, LocalPhone } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';

// Social media data for easy management
const socialLinks = [
  { href: "https://www.facebook.com/MedResInc", imgSrc: fbLogo, label: 'nav_Facebook' },
  { href: "https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F169265954610%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink%26source_id%3D1441792%26recurring_notification%3D0", imgSrc: messengerLogo, label: 'nav_Messenger' },
  { href: "https://www.instagram.com/medicalresourcesinc/?hl=en", imgSrc: instaLogo, label: 'nav_Instagram' },
  { href: "https://medicalresourcesincdot.wordpress.com/", imgSrc: blogLogo, label: 'nav_MDRBlog' }
];

function SocialLink({ href, imgSrc, label }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Link component={ReactLink} to={href} target="_blank" underline="none" sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Avatar alt={t(label)} src={imgSrc} sx={{ filter: 'grayscale(1) sepia(1) hue-rotate(216deg)' }} />
      <Hidden mdDown>
        <Typography variant='h6' color={theme.palette.text.primary}>{t(label)}</Typography>
      </Hidden>
    </Link>
  );
}

function Navigation() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"))
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const [lastScrollY, setLastScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showTopBox, setShowTopBox] = useState(true);

  const closeMenu = () => {
    setMenuOpen(false)
  }
  const openMenu = () => {
    setMenuOpen(true)
  }

  const activeTab = routeToTabValue[location.pathname] ?? false;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // If scrolling up or at the top of the page
      if (currentScrollY < lastScrollY || currentScrollY === 0) {
        setShowTopBox(true);
      } else {
        setShowTopBox(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  function changePage(e, v) {
    let pageName = Object.keys(routeToTabValue)[v].split('/')[1];
    document.title = (pageName ? pageName.charAt(0).toUpperCase() + pageName.slice(1) : "Home") + " | Medical Resources Inc";
    navigate(Object.keys(routeToTabValue)[v]);
    closeMenu()
  }

  return (
    <Paper elevation={3} sx={{ borderRadius: '0 0 14px 14px', zIndex: 100, position: 'sticky', top: 0, width: '100%', transform: showTopBox ? 'translateY(0)' : 'translateY(-50%)', transition: 'transform 0.3s ease-in-out' }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
        <Link href="tel:+12055217337" underline="none" sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Avatar sx={{ backgroundColor: theme.palette.primary.light }}><LocalPhone /></Avatar>
          <Hidden mdDown>
            <Typography variant='h6' color={theme.palette.text.primary}>{t('nav_PhoneNumber')}</Typography>
          </Hidden>
        </Link>
        <Box display="flex" gap={2}>
          {socialLinks.map(link => <SocialLink key={link.label} {...link} />)}
        </Box>
        <Link href="https://medicalresources.hmebillpay.com/Home/Index" underline="none" sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography variant='h6' color={theme.palette.text.primary}>{t('nav_PayOnline')}</Typography>
        </Link>
      </Box>
      <Box sx={{ display: 'flex', backgroundColor: theme.palette.background.default, padding: 1, justifyContent: 'space-evenly', maxHeight: 64, overflow: "hidden" }} >
        <img src={logo} alt='logo' style={{ height: 174, transform: "translate(0px, -64px)" }} />
        {isSm ?
          <Button variant='outlined' onClick={openMenu} size='small'>Menu</Button> :
          <Tabs value={activeTab} onChange={changePage} centered={isLg ? false : true}
            scrollButtons={isLg ? true : false} variant={isLg ? "scrollable" : "standard"} allowScrollButtonsMobile={isLg ? true : false}>
            <Tab label={t('nav_Home')} aria-label="Home" />
            <Tab label={t('nav_About')} aria-label="About" />
            <Tab label={t('nav_Services')} aria-label="Services" />
            <Tab label={t('nav_EquipmentSupplies')} aria-label="Equipment & Supplies" />
            <Tab label={t('nav_Contact')} aria-label="Contact" />
            <Tab label={t('nav_Careers')} aria-label="Careers" />
          </Tabs>}
      </Box>
      <Modal
        open={menuOpen}
        onClose={closeMenu}
        aria-labelledby="Website Menu"
        aria-describedby="Select which page you would like to navigate to."
      >
        <Paper sx={{ backgroundColor: theme.palette.background.paper, top: "50%", left: "50%", transform: "translate(50%, 50%)", width: "min-content", px: 4, pt: 4, borderRadius: 4, textAlignLast: "center" }}>
          <List sx={{ backgroundColor: theme.palette.background.default, width: "min-content" }}>
            <ListItemButton onClick={() => changePage(null, 0)} selected={location.pathname === '/'}>
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={() => changePage(null, 1)} selected={location.pathname === '/about'}>
              <ListItemText primary="About Us" />
            </ListItemButton>
            <ListItemButton onClick={() => changePage(null, 2)} selected={location.pathname === '/services'}>
              <ListItemText primary="Services" />
            </ListItemButton>
            <ListItemButton onClick={() => changePage(null, 3)} selected={location.pathname === '/equipment'}>
              <ListItemText primary="Equipment" />
            </ListItemButton>
            <ListItemButton onClick={() => changePage(null, 4)} selected={location.pathname === '/contact'}>
              <ListItemText primary="Contact" />
            </ListItemButton>
            <ListItemButton onClick={() => changePage(null, 5)} selected={location.pathname === '/careers'}>
              <ListItemText primary="Careers" />
            </ListItemButton>
          </List>
          <IconButton onClick={closeMenu} variant="contained"><Close /></IconButton>
        </Paper>
      </Modal>
    </Paper>
  );
}

export default Navigation;

// Table for converting tab numbers to page routes.
const routeToTabValue = {
  '/': 0,
  '/about': 1,
  '/services': 2,
  '/equipment': 3,
  '/contact': 4,
  '/careers': 5,
  '/pay': 6,
}
