import { Box, Typography, Card, CardContent, CardMedia, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import YouStayHome from '../images/YouStayHomeWeWorkForYou.webp'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Services() {
  const theme = useTheme()
  const { t } = useTranslation("Services");

  const structuredData = {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "name": t('services_PediatricRespiratoryEquipmentAndServices'),
        "description": t('services_ChildrenHealthBelief'),
        "url": window.location.href
      },
      {
        "@type": "Service",
        "name": t('services_GuidedHospitalToHomeTransitions'),
        "description": t('services_HospitalToHomeDescription'),
        "provider": {
          "@type": "Organization",
          "name": "Medical Resources Inc"
        },
        "image": "https://medicalresourcesincdot.files.wordpress.com/2023/06/286508557_135329925809310_333686747139252513_n-1.jpg?w=2000&h="
      },
      {
        "@type": "Service",
        "name": t('services_24/7/365HelpAndSupport'),
        "description": t('services_SupportDescription'),
        "provider": {
          "@type": "Organization",
          "name": "Medical Resources Inc"
        },
        "image": YouStayHome
      }
    ]
  };

  return (
    <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Helmet>
        <title>MDR - Services</title>
        <meta name="description" content="Learn more about the pediatric respiratory equipment and services offered by Medical Resources Inc." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 10% 20%, hsl(44 100% 88% / 1) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 60% 70%, hsl(190 100% 90% / 1) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 90% 40%, hsl(333 100% 91% / 1) 0%, rgba(255,255,255,0) 70%)`, // + "40 for hex opacity, which is equal to 0.25 in a 0-1 scale."
      }} />
      <Typography variant="h1" textAlign="center" gutterBottom>
        {t('services_PediatricRespiratoryEquipmentAndServices')}
      </Typography>

      <Typography variant="subtitle1" textAlign="center" gutterBottom>
        {t('services_ChildrenHealthBelief')}
      </Typography>

      <Grid2 container spacing={4} alignItems="center">
        <Grid2 xs={12} sm={8}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h4" textAlign="center" gutterBottom>
                {t('services_GuidedHospitalToHomeTransitions')}
              </Typography>

              <Typography variant="body1" textAlign="center" sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
                {t('services_HospitalToHomeDescription')}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 xs={12} sm={4}>
          <CardMedia
            component="img"
            image="https://medicalresourcesincdot.files.wordpress.com/2023/06/286508557_135329925809310_333686747139252513_n-1.jpg?w=2000&h="
            alt={t('services_SupportAtHomeAlt')}
            style={{ borderRadius: 25, height: 300 }}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={4} alignItems="center">
        <Grid2 xs={12} sm={4}>
          <CardMedia
            component="img"
            image={YouStayHome}
            alt={t('services_SupportAtHomeAlt')}
            style={{ borderRadius: 25, maxWidth: '100%', height: 'auto' }}
          />
        </Grid2>

        <Grid2 xs={12} sm={8}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h4" textAlign="center" gutterBottom>
                {t('services_24/7/365HelpAndSupport')}
              </Typography>

              <Typography variant="body1" textAlign="center" sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
                {t('services_SupportDescription')}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default Services;
