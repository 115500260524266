import { Box, Divider, Paper, Stack, Typography, Button, List, ListItem, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoneyIcon from '@mui/icons-material/Money';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Careers() {
  const theme = useTheme()
  const { t } = useTranslation('Careers');

  return (
    <Box sx={{ height: '-webkit-fill-available', p: 2 }}>
      <Helmet>
        <title>MDR - Careers</title>
        <meta name="description" content="Explore exciting career opportunities at MDR. Join our team and make a difference!" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Careers at MDR",
              "description": "Explore exciting career opportunities at MDR. Join our team and make a difference!",
              "publisher": {
                "@type": "Organization",
                "name": "MDR"
              }
            }
          `}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 10% 20%, hsl(44 100% 88% / 1) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 60% 70%, hsl(190 100% 90% / 1) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 90% 40%, hsl(333 100% 91% / 1) 0%, rgba(255,255,255,0) 70%)`, // + "40 for hex opacity, which is equal to 0.25 in a 0-1 scale."
      }} />
      <Typography variant="h1" textAlign="center" mb={2}>{t('careers_CareersAtMDR')}</Typography>
      <Stack spacing={3} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
        {jobs.length > 0 ? <>
          <Typography textAlign="center" mt={1}>{t('careers_FeelFreeToSendResume')}</Typography>
          {jobs.map((job) => <JobCard key={job.title + job.description} {...job} />)}
        </> : <Box mt={4}>
          <Typography textAlign="center">{t('careers_NoPositions')}</Typography>
          <Typography textAlign="center" mt={1}>{t('careers_FeelFreeToSendResumeForConsideration')}</Typography>
        </Box>}
      </Stack>
    </Box>
  );
}

function JobCard({ title, description, salary, requirements, benefits }) {
  const [open, setOpen] = useState(false);

  const shortDescription = description.substring(0, 400) + (description.length > 400 ? '...' : '');

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 10% 10%, hsl(90, 40%, 85%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 90% 90%, hsl(270, 50%, 85%) 0%, rgba(255,255,255,0) 70%)`
      }} />
      <Typography variant="h4" textAlign="center" mb={2}>{title}</Typography>
      <Divider mb={2} />
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
        <Box flex={1} pr={{ sm: 2 }}>
          <Typography variant="h6">Description:</Typography>
          <Typography>
            {open ? description : shortDescription}
          </Typography>
          <Button onClick={() => setOpen(!open)} endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />} sx={{ mb: 1 }}>
            {open ? "Show Less" : "Read More"}
          </Button>
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            <MoneyIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            Salary: {salary}
          </Typography>
          <Typography variant="h6" mb={1}>Benefits:</Typography>
          <List>
            {benefits.split('\n').map((ben, index) => (
              <ListItem dense key={index}>
                <ListItemIcon><StarIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={ben} />
              </ListItem>
            ))}
          </List>
          <Typography variant="h6" mb={1}>Requirements:</Typography>
          <List>
            {requirements.split('\n').map((req, index) => (
              <ListItem dense key={index}>
                <ListItemIcon><CheckCircleIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={req} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Paper>
  );
}


export default Careers;

const jobs = [
  {
    title: 'Position One',
    description: `You will be responsible for:\nResponsibility One\nResponsibility Two\nResponsibility Three\n`,
    salary: 'Inquire To Learn',
    requirements: `2 Year Degree or Greater\nFluent in English\nCertificate Three`,
    benefits: `401k\nTwo Weeks PTO\n...`
  },
]