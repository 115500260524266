import React, { useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Divider, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

function FeedbackModal() {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setResponse(null)
    setError(null)
    setFeedback("")
    setOpen(false)
  };
  const clearFeedback = () => {
    setResponse(null)
    setError(null)
    setFeedback("")
    setOpen(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const response = await fetch('https://mainsiteapi.mdr.net/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // Add any additional headers here
      },
      body: JSON.stringify({ feedback }) // Convert the JavaScript object to a JSON string
    });

    if (!response.ok) {
      const data = await response.json()
      setError(data)
      setLoading(false)
    } else {
      setResponse("Success!!")
      setLoading(false)
    }
  }

  const handleChange = (e) => {

    setFeedback(e.target.value)
  }

  const { t } = useTranslation();

  return (
    <div>

      <Button variant="contained" color="primary" onClick={handleOpen} sx={{ boxShadow: 'none' }}>
        {t("feedback_feedback")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ?
          <Box sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }}>
            <Typography variant='h2'>Loading</Typography>
            <CircularProgress />
          </Box> :
          error ?
            <Box sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }}>
              <Typography variant='h2'>There was an error!</Typography>
              <Typography>{error}</Typography>
            </Box> :
            response ?
              <Box sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }} textAlign="center">
                <Typography variant='h2'>Submission Successful!</Typography>
                <Button onClick={clearFeedback} variant='contained'>Close</Button>
              </Box> :
              <Box component="form" onChange={handleChange} onSubmit={handleSubmit} sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }}>
                <Typography variant="h2" id="Website Feedback Form Window" textAlign="center">{t("feedback_feedback")}</Typography>
                <Typography textAlign="center">{t("feedback_ifYouHave")}</Typography>
                <Divider sx={{ mb: 1 }} />
                <TextField name='feedback' fullWidth label={t("feedback_feedbackPlease")} variant="outlined" multiline rows={5} value={feedback} />
                <Button type='submit' variant="contained" color="primary" style={{ marginTop: '16px' }} fullWidth>
                  Submit
                </Button>
              </Box>}
      </Modal>
    </div>
  );
}

export default FeedbackModal;
