import { Button, Container, Paper, Typography, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate, useRouteError } from "react-router-dom";

function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  const hasPreviousPage = window.history.length > 1;
  console.log(window.history)

  return (
    <Container
      component={Paper}
      maxWidth="xl"
      style={{ height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
        <ErrorIcon color="error" style={{ fontSize: 100 }} />
        <Typography variant="h2" color="textSecondary">Oops!</Typography>
        <Typography variant="h5">Sorry, an unexpected error has occurred.</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <i>{error.statusText || error.message}</i>
        </Typography>
        {hasPreviousPage ?
          <>
            <Button variant="contained" color="primary" size="large" onClick={() => navigate(-1)}>Go Back</Button>
            <Typography>Or</Typography>
            <Button variant="contained" color="primary" size="large" onClick={() => navigate("/")}>Go To Our Home Page</Button>
          </> :
          <Button variant="contained" color="primary" size="large" onClick={() => navigate("/")}>Home</Button>
        }
      </Box>
    </Container>
  );
}

export default ErrorPage;
