import { Box, Button, CircularProgress, Link, List, ListItem, Paper, TextField, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import MDRLogoFull from "../images/MDRLogoFull.jpg"
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

function Contact() {
  const { t } = useTranslation('Contact');
  const theme = useTheme();
  const testing = true;
  const [contactingData, setContactingData] = useState({
    "First Name": testing ? "Steven" : "",
    "Last Name": testing ? "Marshall" : "",
    Email: testing ? "stevenmarshall@mdr.net" : "",
    Phone: testing ? "2055217337" : "",
    "Reason for Inquiry": testing ? "Subjecting Reason" : "",
    "Your Message": testing ? "Contacting Message" : ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);


  function updateContactingData(e) {
    const { name, value } = e.target;
    setContactingData({ ...contactingData, [name]: value });
  }

  const clearContactUs = () => {
    setLoading(false)
    setError(null)
    setResponse(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const response = await fetch('https://mainsiteapi.mdr.net/contactUs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // Add any additional headers here
      },
      body: JSON.stringify(contactingData) // Convert the JavaScript object to a JSON string
    });

    if (!response.ok) {
      const data = await response.json()
      setError(data)
      setLoading(false)
    } else {
      setResponse("Success!!")
      setLoading(false)
    }
  }

  return (
    <Grid2 container spacing={3} alignItems="center">
      <Helmet>
        <title>MDR - Contact Us</title>
        <meta name="description" content="Learn the best ways you can reach us!" />
        <meta name="keywords" content="contact details, health care, medical resources" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Contact Us",
              "description": "Learn the best ways you can reach us!",
              "publisher": {
                "@type": "Organization",
                "name": "MDR"
              }
            }
          `}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 20% 30%, hsl(40, 90%, 90%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 70% 30%, hsl(210, 50%, 90%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 20% 70%, hsl(10, 70%, 90%) 0%, rgba(255,255,255,0) 70%)`
      }} />
      <Grid2 sm={12} p={2}>
        <Typography variant="h1" textAlign="center">{t('contact_ContactUsTitle')}</Typography>
      </Grid2>
      <Grid2 sm={12} md={4} p={2}>
        <img src={MDRLogoFull} alt={t('contact_FullLogoAlt')} width="100%" style={{ height: "fit-content", borderRadius: 14, boxShadow: theme.shadows[3] }} />
      </Grid2>
      <Grid2 xs={12} sm={6} md={4}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>{t('contact_TelephoneNumbersTitle')}</Typography>
          <List sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
            {[
              ["Main Number (Text or Call)", "+1 (205) 521 7337", "tel:+12055217337"],
              ["Fax Number", "+1 (205) 521 0585", "tel:+12055210585"]
            ].map(([label, email, href]) => (
              <ListItem key={label}>
                <Typography>{label}: <Link href={href}>{email}</Link></Typography>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid2>
      <Grid2 xs={12} sm={6} md={4}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>{t('contact_EmailsTitle')}</Typography>
          <List sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
            {[
              ["Main Email", "WeCare@mdr.net", "email:WeCare@mdr.net"],
              ["Oximeter Log Sheets", "oxilogsheets@mdr.net", "email:oxilogsheets@mdr.net"],
              ["Signed Sales Order", "so@mdr.net", "email:so@mdr.net"]
            ].map(([label, email, href]) => (
              <ListItem key={label}>
                <Typography>{label}: <Link href={href}>{email}</Link></Typography>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid2>
      <Grid2 xs={12} component="form" onChange={updateContactingData} onSubmit={handleSubmit}>
        <Paper elevation={3} sx={{ p: 3 }}>
          {loading ?
            <Box sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }}>
              <Typography variant='h2'>Loading</Typography>
              <CircularProgress />
            </Box> :
            error ?
              <Box sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }}>
                <Typography variant='h2'>There was an error!</Typography>
                <Typography>Error: {error.message}</Typography>
                <Button onClick={clearContactUs} variant='contained'>Try Again?</Button>
              </Box> :
              response ?
                <Box sx={{ width: 400, padding: 2, bgcolor: 'background.paper', m: 'auto', mt: 10 }} textAlign="center">
                  <Typography variant='h2'>Submission Successful!</Typography>
                  <Button onClick={clearContactUs} variant='contained'>Close</Button>
                </Box> :
                <Grid2 container spacing={2} justifyContent="center">
                  <Grid2 xs={12} textAlign="center">
                    <Typography variant="h5" gutterBottom>{t('contact_ContactFormTitle')}</Typography>
                    <Typography color="textSecondary">{t('contact_ContactFormSubtitle')}</Typography>
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    {[
                      ["First Name", "First Name", contactingData["First Name"]],
                      ["Last Name", "Last Name", contactingData["Last Name"]],
                      ["Email", "Email", contactingData.Email, "email"],
                      ["Phone", "Phone", contactingData.Phone, "tel"],
                      ["Reason for Inquiry", "Reason for Inquiry", contactingData["Reason for Inquiry"]]
                    ].map(([label, name, value, type = "text"]) => (
                      <TextField key={name} label={label} value={value} name={name} type={type} fullWidth required variant="filled" color="primary" margin="normal" sx={{ background: theme.palette.background.default, }} />
                    ))}
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <TextField label="Your Message" value={contactingData["Your Message"]} name="Your Message" fullWidth variant="filled" color="primary" required multiline rows={13} sx={{ background: theme.palette.background.default, height: '-webkit-fill-available' }} margin="normal" />
                  </Grid2>
                  <Grid2 xs={12} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="caption" textAlign="center" marginBottom={2}>{t('contact_PrivacyNotice')}</Typography>
                    <Button variant="contained" color="primary" size="large" type="submit">{t('contact_SubmitButton')}</Button>
                  </Grid2>
                </Grid2>
          }
        </Paper>
      </Grid2>
    </Grid2>
  );
}

export default Contact;
