import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import Root from './routes/root';
import ErrorPage from './error-page';
import Home from './routes/home';
import About from './routes/about';
import Services from './routes/services';
import Equipment from './routes/equipment';
import Contact from './routes/contact';
import Careers from './routes/careers';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/dancing-script/400.css';
import '@fontsource/dancing-script/500.css';
import '@fontsource/dancing-script/700.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import { ThemeProvider } from '@mui/material';
import theme from './themes/defaultV2'
import InsuranceInformation from './routes/insuranceInformation';
import ChangeOfProvider from './routes/changeOfProvider';
import './index.css'
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/changeOfProvider',
        element: <ChangeOfProvider />
      },
      {
        path: '/careers',
        element: <Careers />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/equipment',
        element: <Equipment />
      },
      {
        path: '/insuranceInformation',
        element: <InsuranceInformation />
      },
      {
        path: '/services',
        element: <Services />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);