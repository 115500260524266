import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import VideoBackground480pLQ from '../videos/Mdr Background Video 480P - LQ.mp4'
import VideoBackground720pLQ from '../videos/Mdr Background Video 720P - LQ.mp4'
import VideoBackground1080pLQ from '../videos/Mdr Background Video 1080P - LQ.mp4'
// import VideoBackground1080pHQ from '../videos/Mdr Background Video 1080P - HQ.mp4'
// import VideoBackground from '../videos/DemoWebsiteBackground.mp4'
import { useTheme } from "@emotion/react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";
import equipmentItems from "../images/ItemImages/equipmentItems";
import cssGradientBackground from '../images/cssGradientBackgrounds.json'
import { Helmet } from "react-helmet";

function Home() {
  const theme = useTheme();
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [newsAndUpdatePosts, setNewsAndUpdates] = useState({
    posts: [],
    postCount: 0
  })

  useEffect(() => {
    const resizeVideo = () => {
      const video = videoRef.current;
      const aspectRatio = video.videoWidth / video.videoHeight;
      const windowRatio = window.innerWidth / window.innerHeight;

      if (windowRatio > aspectRatio) {
        video.style.width = "100%";
        video.style.height = "auto";
      } else {
        video.style.width = "auto";
        video.style.height = "100vh";
      }
    };

    window.addEventListener("resize", resizeVideo);
    resizeVideo();

    fetch("https://public-api.wordpress.com/rest/v1.1/sites/medicalresources.blog/posts").then((response) => {
      let data = response.json()
      data.then((data) => {
        setNewsAndUpdates({
          posts: data.posts,
          postCount: data.found
        })
      }).catch(err => {
        console.log(err)
      })
    })

    return () => {
      window.removeEventListener("resize", resizeVideo);
    };
  }, []);

  const structuredData = {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "name": "Medical Resources Inc",
        "description": t("home_WelcomeMessage"),
        "url": window.location.href,
        "logo": "URL_OF_YOUR_LOGO" // Replace with the URL of your organization's logo
      },
      ...equipmentItems.map(item => ({
        "@type": "Product",
        "name": item.title,
        "image": item.img,
        "description": item.description
      })),
      ...newsAndUpdatePosts.posts.map(post => ({
        "@type": "BlogPosting",
        "headline": post.title,
        "datePublished": post.modified,
        "url": post.URL,
        "image": Object.entries(post.attachments)[0] ? Object.entries(post.attachments)[0][1].URL : undefined,
        "description": post.excerpt.replace(/(<([^>]+)>)/gi, "") // Remove HTML tags from the excerpt
      }))
    ]
  };

  function getVideoSource() {
    const width = window.innerWidth;

    if (width <= 480) {
      return VideoBackground480pLQ;
    } else if (width <= 720) {
      return VideoBackground720pLQ;
    } else {
      return VideoBackground1080pLQ;
    }
    // else {
    //   return VideoBackground1080pHQ;
    // }
  }

  return (
    <Box>
      <Helmet>
        <title>MDR - Home</title>
        <meta name="description" content="Medical Resources Inc - Your source for pediatric respiratory equipment and more." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <video
        ref={videoRef}
        autoPlay
        className="videoStyle"
        loop
        muted
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: -2,
          objectFit: "cover",
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <source src={getVideoSource()} type="video/mp4" />
      </video>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 90% 10%, hsl(120deg 60% 90% / 70%) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 10% 10%, hsl(300deg 60% 90% / 70%) 0%, rgba(255,255,255,0) 70%)`
      }} />
      <Box sx={{ height: '85vh', display: 'flex', alignItems: 'center', justifyContent: 'center', p: 3, color: 'white' }}>
        <Typography variant="h2" textAlign="center" sx={{ textShadow: "0 2px 10px black", p: 0, backdropFilter: "blur(25px) brightness(0.8)", borderRadius: 4 }}>
          {t('home_HeroMessage')}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.background.default + "fffbf", backdropFilter: `blur(16px)`, p: 3, mb: 2 }}>
        <Grid2 container spacing={4}>
          <Grid2 xs={12} sm={6} justifyContent="center" alignItems="center">
            <Typography variant="h2" textAlign='center'>Medical Resources Inc</Typography>
            <Typography variant="subtitle1" textAlign="center">{t("home_PediatricRespiratorySubtitle")}</Typography>
            <Typography variant="body1" textAlign='center' sx={{ pb: 1 }}>{t("home_WelcomeMessage")}</Typography>
          </Grid2>
          <Grid2 xs={12} sm={6} justifyContent="center" alignItems="center" display="flex" flexDirection="column" sx={() => {
            let randomNumber = Math.floor(Math.random() * cssGradientBackground.length)
            return {
              backgroundColor: cssGradientBackground[randomNumber].backgroundColor,
              backgroundImage: cssGradientBackground[randomNumber].backgroundImage,
              borderRadius: 4,
              p: 1
            }
          }}>
            <Card sx={{ background: theme.palette.background.default, borderRadius: 4 }}>
              <CardMedia image="https://medicalresourcesincdot.files.wordpress.com/2023/02/img_2708.jpg?strip=info&w=960" title="Portrait of Everleigh" sx={{ height: "15rem", background: 'linear-gradient(0deg, white, transparent)', backgroundSize: 'cover', backgroundPosition: 'center' }} />
              <CardContent>
                <Typography variant="h5" textAlign="center">
                  {t("home_ExtraordinaryEverleighTitle")}
                </Typography>
                <Typography variant="body2" textAlign="center">{t("home_ExtraordinaryEverleighDescription")}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" LinkComponent={Link} target="_blank" to="https://medicalresourcesincdot.wordpress.com/2023/02/22/extraordinary-everleigh/" sx={{ my: 1 }} fullWidth>{t("home_ReadMoreAboutEverleigh")}</Button>
              </CardActions>
            </Card>
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.background.default + "fffbf", backdropFilter: `blur(16px)`, p: 3, mb: 2 }}>
        <Grid2 container spacing={4}>
          <Grid2 xs={12} sm={6} justifyContent="center" alignItems="center" sx={() => {
            let randomNumber = Math.floor(Math.random() * cssGradientBackground.length)
            return {
              backgroundColor: cssGradientBackground[randomNumber].backgroundColor,
              backgroundImage: cssGradientBackground[randomNumber].backgroundImage,
              borderRadius: 4,
              p: 1
            }
          }}>
            <Card sx={{ background: theme.palette.background.default, borderRadius: 4 }}>
              <CardMedia image="https://medicalresourcesincdot.files.wordpress.com/2023/06/356384116_3122423218061111_6144905928241311388_n.jpg?w=2000&h=" title="Portrait of Everleigh" sx={{ height: "20rem", background: 'linear-gradient(0deg, white, transparent)', backgroundSize: 'cover', backgroundPosition: 'center' }} />
              <CardContent>
                <Typography variant="h5" textAlign="center">
                  {t("home_LovableLucasTitle")}
                </Typography>
                <Typography variant="body2" textAlign="center">{t("home_LovableLucasDescription")}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" LinkComponent={Link} target="_blank" to="https://medicalresourcesincdot.wordpress.com/2023/06/28/lovable-lucas/" sx={{ my: 1 }} fullWidth>{t("home_ReadMoreAboutLucas")}</Button>
              </CardActions>
            </Card>
          </Grid2>
          <Grid2 xs={12} sm={6} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
            <Typography variant="h2" textAlign="center">{t("home_CompassionGuidingHandsTitle")}</Typography>
            <Typography variant="body1" textAlign='center'>{t("home_CompassionGuidingHandsDescription")}</Typography>
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.background.default + "fffbf", backdropFilter: `blur(16px)`, p: 3, mb: 2 }}>
        <Grid2 container spacing={4}>
          <Grid2 xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h3" textAlign='center'>{t("home_RespiratoryEquipmentTitle")}</Typography>
          </Grid2>
          <Grid2 xs={12} container spacing={4} justifyContent="space-between">
            {equipmentItems.map(({ img, title, mediaSizes }) => (
              <Grid2 key={title} xs={mediaSizes.xs} sm={mediaSizes.sm} md={mediaSizes.md} lg={mediaSizes.lg}>
                <Card elevation={3}>
                  <CardMedia image={img} title={title} sx={{ height: 240 }} />
                  <CardContent>
                    <Typography textAlign='center' variant="h5">{title}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
            ))}
          </Grid2>
          <Grid2 xs={12} display="flex" flexDirection="column" alignItems="center">
            <Button variant="contained" color="primary" size="large" LinkComponent={Link} to="/equipment">{t("home_ReadMoreAboutEquipment")}</Button>
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.background.default + "fffbf", backdropFilter: `blur(16px)`, p: 3 }}>
        <Grid2 container spacing={4}>
          <Grid2 xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h3" textAlign='center'>{t("home_NewsAndUpdatesTitle")}</Typography>
          </Grid2>
          {
            newsAndUpdatePosts.posts.map(({ ID, modified, title, attachments, like_count, excerpt, URL }, index) => {
              let postDate = new Date(modified);
              if (index < 4) {
                return (
                  <Grid2 xs={12} sm={6} md={4} lg={3} key={ID}>
                    <Card elevation={4} sx={{ backgroundColor: theme.palette.background.default }}>
                      <CardHeader title={title} subheader={postDate.toDateString() + " - " + like_count + " Likes"} />
                      {Object.entries(attachments)[0] ? <CardMedia component="img" image={Object.entries(attachments)[0][1].URL} height={250} alt={title} /> : null}
                      <CardContent dangerouslySetInnerHTML={{ __html: excerpt }} sx={{ maxHeight: '13rem', overflow: 'hidden', background: 'linear-gradient(0deg, white, transparent)' }}>
                      </CardContent>
                      <CardActions>
                        <Button variant="contained" fullWidth LinkComponent={Link} target="_blank" to={URL}>{t("home_ReadFullPost")}</Button>
                      </CardActions>
                    </Card>
                  </Grid2>
                )
              } else if (index < 8) {
                return (
                  <Grid2 xs={12} sm={6} md={4} lg={3} key={ID}>
                    <Card elevation={4} sx={{ backgroundColor: theme.palette.background.default }}>
                      <CardHeader title={title} subheader={postDate.toDateString() + " - " + like_count + " Likes"} />
                      <CardActions>
                        <Button variant="contained" fullWidth LinkComponent={Link} target="_blank" to={URL}>{t("home_ReadFullPost")}</Button>
                      </CardActions>
                    </Card>
                  </Grid2>
                )
              } else { return null }
            })
          }
          <Grid2 xs={12} spacing={4} p={4} alignItems="center" display="flex" flexDirection="column">
            <Typography variant="h5" gutterBottom>{t("home_MorePostsMessage")}</Typography>
            <Button variant="contained" LinkComponent={Link} target="_blank" to="https://medicalresourcesincdot.wordpress.com/">{t("home_CheckItOut")}</Button>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}

export default Home;