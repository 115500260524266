import { useTheme } from "@emotion/react";
import { Avatar, Box, Button, Card, CardContent, CardMedia, Paper, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import crayonChildUsingNebMask from "../images/crayonChildUsingNebMask.webp"
import ventCampVideo from "../videos/Vent Camp.m4v"
import KathyJ from "../images/staffPhotos/KathyJ.jpg"
import ventCampPoster from '../images/ventCampPoster.png'
import storeFrontImage from "../images/storeFrontImage.webp"
import { staffObject } from "../images/staffPhotos/staff"
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function About() {
  const theme = useTheme()
  let isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const { t } = useTranslation("about")

  return (
    <Box sx={{ gap: isSm ? 1 : 4, p: 2 }}>
      <Helmet>
        <title>MDR - About Us</title>
        <meta name="description" content="Learn more about MDR and our mission." />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "AboutPage",
              "name": "About MDR",
              "description": "Learn more about MDR and our mission.",
              "publisher": {
                "@type": "Organization",
                "name": "MDR"
              }
            }
          `}
        </script>
      </Helmet>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,
        background: `radial-gradient(circle at 10% 20%, hsl(0 100% 88% / 1) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 60% 70%, hsl(120 100% 90% / 1) 0%, rgba(255,255,255,0) 70%),radial-gradient(circle at 90% 40%, hsl(240 100% 91% / 1) 0%, rgba(255,255,255,0) 70%)`, // + "40 for hex opacity, which is equal to 0.25 in a 0-1 scale."
      }} />
      <Box elevation={3} sx={{ p: 2, borderRadius: 4, marginBottom: 2 }}>
        <Typography variant="h1" textAlign="center" gutterBottom>
          {t("about_TheBestInPediatricRespiratory")}
        </Typography>
        <Grid2 container spacing={2} alignItems="center" justifyContent="center">
          <Grid2 xs={12} sm={4} >
            <CardMedia
              image={storeFrontImage}
              alt="Front of MDR Office"
              style={{ height: 450, borderRadius: 16, backgroundSize: 'cover' }}
            />
          </Grid2>
          <Grid2 xs={12} sm={8} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, p: 1 }}>
            <Typography variant="subtitle1" textAlign="center" gutterBottom>
              {t("about_ServicingAlabamaSince2000")}
            </Typography>
            <Typography paragraph>
              {t("about_BeingFoundedMessage")}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h2" textAlign="center" gutterBottom>
          {t("about_MessageFromFounders")}
        </Typography>
        <Grid2 container spacing={2}>
          {[
            { name: "Bruce", message: t("about_BruceMessage") },
            { name: "Kathy", message: t("about_KathyMessage") }
          ].map(({ name, message }) => (
            <Grid2 xs={12} sm={6} key={name}>
              <Card elevation={3} sx={{ borderRadius: 4 }}>
                <CardMedia
                  image={name === "Bruce" ? "#" : KathyJ}
                  title={`Portrait of ${name}`}
                  style={{ height: '25rem', backgroundPosition: "center" }}
                />
                <CardContent sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, m: 4 }}>
                  <Typography variant="h5" textAlign="center" gutterBottom>
                    {name}
                  </Typography>
                  <Typography textAlign="center">
                    {message}
                  </Typography>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>

      <Paper elevation={3} sx={{ p: 2, borderRadius: 4, marginBottom: 2 }}>
        <Typography variant="h3" textAlign="center" gutterBottom>
          {t("about_StaffMembers")}
        </Typography>

        <Typography variant="subtitle1" textAlign="center" gutterBottom>
          {t("about_YourUnwaveringPartners")}
        </Typography>

        {Object.keys(staffObject).map(departmentName => (
          <Box key={departmentName} sx={{ margin: 2 }}>
            <Typography variant="h4" textAlign="center" gutterBottom>
              {departmentName}
            </Typography>

            <Grid2 container spacing={2} justifyContent="center" alignItems="center">
              {staffObject[departmentName].map(({ position, profileImage, name, quote, hiddenMessage }) => (
                <Grid2 xs={12} sm={6} md={4} key={name} id={name}>
                  <Card elevation={3} sx={{ p: 2, borderRadius: 4, backgroundColor: theme.palette.background.default }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {hiddenMessage ?
                        <Tooltip title={hiddenMessage} leaveTouchDelay={5000}>
                          <Avatar src={profileImage || ""} sx={{ width: 128, height: 128 }} alt={name}>
                            {name.split(" ").map(n => n[0]).join("")}
                          </Avatar>
                        </Tooltip>
                        :
                        <Avatar src={profileImage || ""} sx={{ width: 128, height: 128 }} alt={name}>
                          {name.split(" ").map(n => n[0]).join("")}
                        </Avatar>
                      }
                      <Typography variant="h6" textAlign="center" gutterBottom>
                        {name}
                      </Typography>
                      <Typography variant="subtitle1" textAlign="center">
                        {position}
                      </Typography>
                      {quote && <Typography variant="body2" textAlign="center">{quote}</Typography>}
                    </Box>
                  </Card>
                </Grid2>
              ))}
            </Grid2>
          </Box>
        ))}

        <Typography textAlign="center" gutterBottom>
          {t("about_OurRespiratoryTherapistsMessage")}
        </Typography>
      </Paper>

      <Grid2 container spacing={2} alignItems="center">
        <Grid2 xs={12} sm={8}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            {t("about_WeAreHereToHelp")}
          </Typography>
          <Typography textAlign="center" paragraph>
            {t("about_WeLoveSeeingChildrenMessage")}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" size="large">{t("about_ContactUs")}</Button>
          </Box>
        </Grid2>

        <Grid2 xs={12} sm={4}>
          <img src={crayonChildUsingNebMask} alt="Crayon drawing of a child with respiratory mask." style={{ maxWidth: '100%', borderRadius: 25 }} />
        </Grid2>
      </Grid2>

      <Paper elevation={3} sx={{ p: 2, borderRadius: 4, marginTop: 2 }}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          {t("about_MdRSponsoredOrganizations")}
        </Typography>

        {[
          {
            name: "Vent Camp!",
            video: ventCampVideo,
            message: t("about_WeCherishOurCommunities")
          },
        ].map(({ name, video, message }) => (
          <Grid2 key={name} container spacing={2} alignItems="center">
            <Grid2 xs={12} sm={6}>
              <video src={video} poster={ventCampPoster} controls style={{ width: '100%', borderRadius: 24 }} />
            </Grid2>
            <Grid2 xs={12} sm={6} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4 }}>
              <Typography variant="h5" textAlign="center" gutterBottom>
                {name}
              </Typography>
              <Typography textAlign="center">
                {message}
              </Typography>
            </Grid2>
          </Grid2>
        ))}
      </Paper>
    </Box>
  );
}

export default About;
